import { stringToId } from '../../../helpers/stringToId'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { Link } from '../Link'
import { SVGIcon } from '../SVGIcon'

export interface IMenuLinks {
  title: string
  elementList: Array<{
    title: string
    link: string
    elementList?: Array<{ title: string; link: string }>
  }>
  openByDefault?: boolean
}

export function MenuLinks({ title, elementList, openByDefault }: IMenuLinks): JSX.Element {
  const dropMenuId = stringToId(`drop-menu-${title}`)
  const { menuHit } = useTMSHitForMenuBurger()

  return (
    <>
      <div className="MenuLinks">
        <input
          className="MenuLinks__Input"
          type="checkbox"
          id={dropMenuId}
          defaultChecked={openByDefault}
        />
        <label
          htmlFor={dropMenuId}
          className="MenuLinks__Dropdown flex"
          onClick={() => menuHit(title)}
        >
          <div className="MenuLinks__Chevron">
            <SVGIcon name="chevron" orientation="up" />
          </div>
          <div className="MenuLinks__Title">{title}</div>
        </label>
        <ul className="MenuLinks__List">
          {elementList?.length
            ? elementList.map(({ title: itemTitle, link: itemLink, elementList: menuList }) =>
                // Submenu with links
                menuList?.length ? (
                  <li key={stringToId(itemTitle + itemLink)} className="MenuLinks__SubMenu">
                    <Link url={itemLink} onClick={() => menuHit(title, itemTitle)}>
                      <div className="MenuLinks__SubMenu__Title MenuLinks__Link flex">
                        {itemTitle}
                      </div>
                    </Link>
                    <ul className="MenuLinks__List_Sublist">
                      {menuList?.length &&
                        menuList.map(({ title: linkTitle, link }) => (
                          <li
                            key={link}
                            className="MenuLinks__Item MenuLinks__Link"
                            onClick={() => menuHit(title, itemTitle, linkTitle)}
                          >
                            <Link url={link}>{linkTitle}</Link>
                          </li>
                        ))}
                    </ul>
                  </li>
                ) : (
                  // link
                  <li
                    key={itemLink}
                    className="MenuLinks__Item MenuLinks__Link"
                    onClick={() => menuHit(title, itemTitle)}
                  >
                    <Link url={itemLink}>{itemTitle}</Link>
                  </li>
                ),
              )
            : null}
        </ul>
      </div>

      <style jsx>{`
        #${dropMenuId}:checked + .MenuLinks__Dropdown {
          margin-bottom: 38px;
        }

        #${dropMenuId}:checked + .MenuLinks__Dropdown > .MenuLinks__Chevron :global(> svg) {
          transform: rotate(90deg);
        }

        #${dropMenuId}:checked + label + .MenuLinks__List {
          display: block;
          visibility: visible;
        }
      `}</style>
      <style jsx>{`
        .MenuLinks {
          width: 100%;
          padding: 0 30px 60px;
        }

        .MenuLinks__Dropdown {
          cursor: pointer;
          border: none;
          background: transparent;
          padding: 0;
        }

        .MenuLinks__Input {
          display: none;
          visibility: hidden;
        }

        .MenuLinks__Chevron :global(svg) {
          transition: transform 0.3s ease;
        }

        .MenuLinks__Dropdown :global(svg) {
          margin-right: 4px;
        }

        .MenuLinks__Title {
          font-size: 30px;
          font-weight: 700;
          line-height: 116%;
          color: var(--color-deepBlue);
          margin: 0;
        }

        .MenuLinks__List,
        .MenuLinks__List_Sublist {
          list-style: none;
          margin: 0;
          padding: 0 0 0 30px;
        }

        .MenuLinks__List {
          display: none;
          visibility: hidden;
        }

        .MenuLinks__Item {
          margin-bottom: 30px;
        }

        .MenuLinks__Item :last-child {
          margin-bottom: 0;
        }

        .MenuLinks__SubMenu {
          width: 100%;
          padding: 0;
          margin-bottom: 30px;
        }

        .MenuLinks__SubMenu__Title {
          padding: 0;
          margin: 0 0 30px 0;
        }

        .MenuLinks__Link {
          color: var(--color-deepBlue);
          font-weight: 300;
          font-size: 18px;
          line-height: 144%;
        }
      `}</style>
    </>
  )
}
