import { Link } from '../../atoms/Link'
import { stringToId } from '../../../helpers/stringToId'
import { Topic } from '../../atoms/Topic'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { SVGIcon } from '../../atoms/SVGIcon'
import { theme } from '../../../styles'

export interface IMenuTopics {
  title: string
  elementList: Array<{ title: string; link: string; color: string }>
  more: string
}

export function MenuTopics({ title, elementList, more }: IMenuTopics): JSX.Element {
  const { menuHit } = useTMSHitForMenuBurger()
  const dropMenuId = `drop-topics-${stringToId(title)}`

  return (
    <>
      <div className="MenuTopics">
        <input
          className="MenuTopics__Input"
          type="checkbox"
          id={dropMenuId}
          defaultChecked={true}
        />
        <label
          htmlFor={dropMenuId}
          className="MenuTopics__Dropdown flex"
          onClick={() => menuHit(title)}
        >
          <div className="MenuTopics__Chevron">
            <SVGIcon name="chevron" orientation="up" />
          </div>
          <div className="MenuTopics__Title">{title}</div>
        </label>
        <ul className="MenuTopics__List flex-column">
          {elementList?.map(({ title: categoryName, link, color }, index) => (
            <li
              key={index}
              className="MenuTopics__Item"
              onClick={() => menuHit(title, categoryName)}
            >
              <Topic link={link} color={color} title={categoryName} />
            </li>
          ))}
          <Link url="/sujets/" onClick={() => menuHit(title, more)}>
            <span className="MenuTopics__More">{more}</span>
          </Link>
        </ul>
      </div>

      <style jsx>{`
        #${dropMenuId}:checked + .MenuTopics__Dropdown > .MenuTopics__Chevron :global(> svg) {
          transform: rotate(90deg);
        }

        #${dropMenuId}:checked + label + .MenuTopics__List {
          display: flex;
          visibility: visible;
        }
      `}</style>
      <style jsx>{`
        .MenuTopics {
          width: 100%;
          padding: 0 0 60px;
        }

        .MenuTopics__Dropdown {
          cursor: pointer;
          border: none;
          background: transparent;
          padding: 37px 30px 37px;
        }

        .MenuTopics__Input {
          display: none;
          visibility: hidden;
        }

        .MenuTopics__Dropdown :global(svg) {
          transition: transform 0.3s ease;
          margin-right: 4px;
        }

        .MenuTopics__Title {
          font-family: ${theme.cssVars.overpass};
          font-size: 30px;
          font-weight: 700;
          line-height: 116%;
          color: ${theme.cssVars.deepBlue};
          margin: 0;
        }

        .MenuTopics__List {
          display: none;
          visibility: hidden;
          list-style: none;
          margin: 0;
          padding: 0 4px;
          gap: 4px;
        }

        .MenuTopics__More {
          display: block;
          margin: 30px 30px 0 30px;
          font-family: ${theme.cssVars.overpass};
          font-weight: 800;
          font-size: 17px;
          line-height: 141%;
          text-decoration-line: underline;
          color: ${theme.cssVars.deepBlue};
        }
      `}</style>
    </>
  )
}
