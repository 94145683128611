import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { MENUMAIN_ID, MENU_ID } from '../../../constants/components'
import { useUser } from '../../../hook/useUser'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { SVGIcon } from '../SVGIcon'

export interface IMenu {
  children: JSX.Element
}

export function Menu({ children }: IMenu): JSX.Element {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const { isLoggedIn } = useUser()
  const { events } = useRouter()
  const { menuHit } = useTMSHitForMenuBurger()

  function handleRouteChange() {
    // Close menu when route change
    setMenuOpen(false)
  }

  useEffect(() => {
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events])

  return (
    <>
      <nav
        role="navigation"
        aria-label="Menu principal"
        id={MENUMAIN_ID}
        tabIndex={-1}
        className="Menu__Wrapper flex"
      >
        <input
          className="Menu_Input"
          id={MENU_ID}
          type="checkbox"
          autoComplete="off"
          checked={isMenuOpen}
          onChange={() => {
            menuHit(!isMenuOpen ? 'ouverture' : 'fermeture')
            setMenuOpen(!isMenuOpen)
          }}
        />
        <label htmlFor={MENU_ID} className="Menu__BackDrop" />
        <div className="Menu">
          <div className="Menu__Nav flex justify-end items-center">
            <label
              title={isMenuOpen ? 'Fermer le menu' : 'Ouvrir le menu'}
              className="Menu__Close"
              htmlFor={MENU_ID}
            >
              <SVGIcon
                name="close"
                size={32}
                primaryColor={!isLoggedIn ? 'var(--color-deepBlue)' : 'var(--color-white)'}
                focusable={false}
                aria-hidden="true"
              />
            </label>
          </div>
          {children}
        </div>
      </nav>

      <style jsx global>{`
        html {
          ${isMenuOpen ? 'overflow: hidden;' : ''};
        }
      `}</style>

      <style jsx>{`
        .Menu__Nav {
          background-color: ${!isLoggedIn ? 'var(--color-white)' : 'var(--color-darkestBlue)'};
        }
      `}</style>

      <style jsx>{`
        .Menu__BackDrop {
          display: block;
          opacity: 0;
        }

        #${MENU_ID}:checked ~ .Menu__BackDrop {
          display: block;
          background-color: var(--color-backDropColor);
          z-index: 8999;
          width: 100vw;
          height: 100%;
          opacity: 1;
          transition: all 0.5s ease-in;
        }

        .Menu__Wrapper {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
        }

        .Menu {
          z-index: 9000;
          max-width: 395px;
          background: var(--color-white);
          position: fixed;
          top: 0;
          right: 0;
          width: 100vw;
          height: 100%;
          overflow-y: scroll;
          transform: translateX(100%);
          transition: transform 0.5s ease-in-out;
        }

        .Menu_Input {
          display: none;
          visibility: hidden;
        }

        #${MENU_ID}:checked ~ .Menu {
          transform: translateX(0%);
        }

        .Menu__Nav {
          padding: 30px;
        }

        .Menu__Close {
          cursor: pointer;
          border: none;
          padding: 0;
          background: transparent;
        }
      `}</style>
    </>
  )
}
