export function Play({
  id,
  className,
  size = 24,
}: {
  id?: string
  className?: string
  size?: number
}): JSX.Element {
  return (
    <svg
      id={id}
      className={className}
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="13.5" r="10" fill="var(--color-lightBlue)" />
      <circle cx="13.5001" cy="13.5002" r="12.2647" stroke="var(--color-lightBlue)" />
      <path
        d="M10.5586 13.5779V9.46021L18.2057 13.5779L10.5586 17.6955V13.5779Z"
        stroke="var(--color-deepBlue)"
        strokeWidth="1.59"
        strokeLinejoin="bevel"
      />
    </svg>
  )
}
