import { Context, MutableRefObject, useEffect } from 'react'
import type { IPicture } from '../types/pictures'
import type { IPageData } from '../types/page'

import { createContext, useState, useRef } from 'react'
import { CarouselFullScreen } from '../components/molecules/CarouselFullScreen'
import { getBlockFromMainBody } from '../helpers/blocks'

export interface ICarouselFullScreenProps {
    hasFullScreen: boolean
    setHasFullScreen: (hasFullScreen: boolean) => void
    childrenCarousel: MutableRefObject<IPicture[]>
}

export type ICarouselFullScreenContext = Context<ICarouselFullScreenProps>

export const carouselFullScreenContext: ICarouselFullScreenContext = createContext({ hasFullScreen: false, setHasFullScreen: () => null, childrenCarousel: null })

export function CarouselFullScreenContext({
    page,
    children,
}: {
    page: IPageData
    children?: JSX.Element[]
}): JSX.Element {
    const [hasFullScreen, setHasFullScreen] = useState(false)
    const childrenCarousel = useRef(null)
    const scrollTop = useRef(0)
    const hasCarouselImages = !!getBlockFromMainBody(page, 'carousel-images')
    const HandleSetHasFullScreen = (value) => {
        scrollTop.current = window?.pageYOffset ?? 0
        setHasFullScreen(value)
    }
    useEffect(() => {
        if (!hasFullScreen && window?.pageYOffset !== scrollTop.current) {
            window.scrollTo({ top: scrollTop.current })
        }
    }, [hasFullScreen])

    if (!hasCarouselImages) return <>{children}</>

    return (
        <carouselFullScreenContext.Provider value={{ hasFullScreen, setHasFullScreen: HandleSetHasFullScreen, childrenCarousel }}>
            {hasFullScreen && childrenCarousel?.current && <CarouselFullScreen setHasFullScreen={setHasFullScreen} elementList={childrenCarousel.current} />}
            {children}
        </carouselFullScreenContext.Provider>
    )
}
