import type { ComponentType } from 'react'
import type { IHeaderHome } from '../../molecules/HeaderHome'
import type { IHeaderPage } from '../../molecules/HeaderPage'
import type { ISponsorBanner } from '../../molecules/SponsorBanner'
import type { IPicture } from '../../molecules/Picture'
import type { IOutdatedBanner } from '../../molecules/OutdatedBanner'

import { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { DownloadBanner, IDownloadBanner } from '../../molecules/DownloadBanner'
import { DownloadBannerContextWrapper } from '../../../contexts/DownloadBanner'

const HeaderHome: ComponentType<IHeaderHome> = dynamic(function HeaderHome() {
  return import('../../molecules/HeaderHome').then((module) => module.HeaderHome)
})
const HeaderPage: ComponentType<IHeaderPage> = dynamic(function HeaderPage() {
  return import('../../molecules/HeaderPage').then((module) => module.HeaderPage)
})
const HeaderVideo: ComponentType<IHeaderPage> = dynamic(function HeaderVideo() {
  return import('../../molecules/HeaderVideo').then((module) => module.HeaderVideo)
})

export interface IHeader {
  topic?: {
    title: string
    color: string
    link: string
  }
  sponsor?: ISponsorBanner
  eventLogo?: {
    pictures: IPicture
    link: string
  }
  elementList: {
    title: string
    bgColor: string
    link: string
    icon?: string
  }[]
  downloadBanner?: IDownloadBanner
  outdatedBanner?: IOutdatedBanner
  wordings?: {
    login?: string
    direct?: {
      title: string
      link: string
    }
  }
}

export function Header({
  elementList,
  sponsor,
  eventLogo,
  downloadBanner,
  outdatedBanner,
  topic,
  wordings,
}: IHeader): JSX.Element {
  const { asPath } = useRouter()
  const { type, subtype } = useContext(ApiResponseContext)
  const hash = asPath?.split('#')[1]
  const asPathWithoutHash = asPath?.split('?')[0].replace(`#${hash}`, '')
  const pathname = asPathWithoutHash.split('/')[1]

  const currentHomeConfig = elementList?.find(
    (elem) => asPathWithoutHash === elem.link || elem.link === `/${pathname}/`,
  )

  const headerType = useMemo(() => {
    if (currentHomeConfig || subtype === 'home')
      return (
        <HeaderHome
          elementList={elementList}
          link={currentHomeConfig?.link || '/'}
          wordings={wordings}
        />
      )

    if (type === 'content' && subtype === 'video') {
      return <HeaderVideo topic={topic} />
    }

    return (
      <HeaderPage
        eventLogo={eventLogo}
        sponsor={sponsor}
        outdatedBanner={outdatedBanner}
        topic={topic}
        wordings={wordings}
      />
    )
  }, [currentHomeConfig, subtype])

  return (
    <>
      <DownloadBannerContextWrapper enabled={!!downloadBanner}>
        {downloadBanner ? <DownloadBanner {...downloadBanner} /> : null}
        {headerType}
      </DownloadBannerContextWrapper>
    </>
  )
}
