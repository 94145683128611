import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'
import { useTMSHitForMenuBurger } from '../../../hook/useTMSClickHit'
import { theme } from '../../../styles'
import { FacilitiButton } from '../../atoms/FacilitiButton'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface IMenuFooter {
  cookiesSettings: string
  downloadApps: string
  accessibilityTitle: string
  accessibilityAlt: string
}

export function MenuFooter({
  cookiesSettings,
  downloadApps,
  accessibilityTitle,
  accessibilityAlt,
}: IMenuFooter): JSX.Element {
  const isAmp = useAmp()
  const { push } = useRouter()
  const { menuHit } = useTMSHitForMenuBurger()

  const openPrivacyCenter = (): void => {
    menuHit(cookiesSettings)
    window?.tC?.privacyCenter?.showPrivacyCenter()
  }

  const handleOnClickApple = () => {
    menuHit('telecharger-applis-ios')
    push('https://apps.apple.com/fr/app/lci-lactualite-en-continu/id426125722')
  }

  const handleOnClickAndroid = () => {
    menuHit('telecharger-applis-android')
    push('https://play.google.com/store/apps/details?id=com.smartnsoft.metro')
  }

  return (
    <>
      <div className="MenuFooter">
        <div className="MenuFooter__Settings flex flex-column">
          <button
            type="button"
            onClick={openPrivacyCenter}
            className="MenuFooter__AccountLink flex justify-between items-center"
          >
            <div className="MenuFooter__AccountLink__Label flex flex-column items-center">
              {cookiesSettings}
            </div>
            <SVGIcon name="arrow" />
          </button>
        </div>
        <div className="MenuFooter__Accessibility flex flex-column">
          <FacilitiButton alt={accessibilityAlt} title={accessibilityTitle} />
        </div>
        <div className="MenuFooter__Download flex flex-column justify-center items-center">
          <span className="MenuFooter__Download_Label">{downloadApps}</span>
          <div className="MenuFooter__Download_Brands">
            <a
              className="MenuFooter__Download_Item"
              href={
                isAmp ? 'https://apps.apple.com/fr/app/lci-lactualite-en-continu/id426125722' : '#'
              }
              onClick={handleOnClickApple}
            >
              <img
                src="/images/logos/applestore.svg"
                height={32.5}
                width={102.8}
                alt="Logo Apple Store"
              />
            </a>
            <a
              className="MenuFooter__Download_Item"
              href={
                isAmp ? 'https://play.google.com/store/apps/details?id=com.smartnsoft.metro' : '#'
              }
              onClick={handleOnClickAndroid}
            >
              <img
                src="/images/logos/playstore.svg"
                height={32.5}
                width={109.7}
                alt="Logo PlayStore"
              />
            </a>
          </div>
        </div>
      </div>

      <style jsx>{`
        .MenuFooter {
          width: 100%;
        }

        .MenuFooter__Settings {
          padding: 0 30px 30px 30px;
        }

        .MenuFooter__Accessibility {
          padding: 0 30px 60px 30px;
        }

        .MenuFooter__Accessibility > :global(button) {
          color: ${theme.cssVars.deepBlue};
          font-weight: 800;
          font-size: 18px;
        }

        .MenuFooter__AccountLink {
          cursor: pointer;
          padding: 8px 0;
          border: none;
          background-color: transparent;
        }

        .MenuFooter__AccountLink__Label {
          color: ${theme.cssVars.deepBlue};
          font-weight: 700;
          font-size: 18px;
          line-height: 144%;
        }

        .MenuFooter__Download {
          background: ${theme.cssVars.deepBlue};
          padding: 30px;
        }

        .MenuFooter__Download_Label {
          color: var(--color-white);
          margin-bottom: 30px;
        }

        .MenuFooter__Download_Item {
          margin-right: 30px;
        }

        .MenuFooter__Download_Item :last-child {
          margin-right: 0;
        }
      `}</style>
    </>
  )
}
